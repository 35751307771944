import React, { useState, useEffect, useContext } from "react"
import CartContext from "../contexts/CartContext"
import axios from "axios"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import { navigate } from "gatsby"

import CheckoutError from "../components/checkoutError"

import styles from "./checkoutForm.module.scss"

const CheckoutForm = ({ sum }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [zipCode, setZipCode] = useState("")

  const { cart } = useContext(CartContext)

  const [isProcessing, setProcessingTo] = useState(false)
  const [checkoutError, setCheckoutError] = useState(null)

  const [token, setToken] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    const loadToken = async () => {
      try {
        const { data: client_secret } = await axios.post(
          "https://wistibike.herokuapp.com/orders/payment_intents",
          {
            amount: sum * 100,
          }
        )
        setToken(client_secret)
      } catch (error) {
        navigate(`/checkout-failure/`)
      }
    }
    loadToken()
  }, [cart])

  const handleCardDetailsChange = e => {
    e.error ? setCheckoutError(e.error.message) : setCheckoutError(null)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const billingDetails = {
      name: lastName,
      email,
      phone: phoneNumber,
      address: {
        city,
        line1: address,
        postal_code: zipCode,
      },
    }

    setProcessingTo(true)

    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      })

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message)
        setProcessingTo(false)
        return
      }

      const { error } = await stripe.confirmCardPayment(token, {
        payment_method: paymentMethodReq.paymentMethod.id,
      })

      if (error) {
        setCheckoutError(error.message)
        setProcessingTo(false)
        return
      }
    } catch (error) {
      navigate(`/checkout-failure/`)
    }

    navigate(`/checkout-success/`)
  }

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "18px",
        color: "black",
        "::placeholder": {
          color: "black",
        },
      },
      invalid: {
        color: "red",
        iconColor: "red",
      },
    },
    hidePostalCode: true,
  }

  return (
    <form id={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formInputs}>
        <label htmlFor="firstName">Prénom</label>
        <input
          id="firstName"
          type="text"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          required
        />
      </div>
      <div className={styles.formInputs}>
        <label htmlFor="lastName">Nom de famille</label>
        <input
          id="lastName"
          type="text"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          required
        />
      </div>
      <div className={styles.formInputs}>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
      </div>
      <div className={styles.formInputs}>
        <label htmlFor="phoneNumber">Numéro de téléphone</label>
        <input
          id="phoneNumber"
          type="text"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          required
        />
      </div>
      <div className={styles.formInputs}>
        <label htmlFor="address">Adresse</label>
        <input
          id="address"
          type="text"
          value={address}
          onChange={e => setAddress(e.target.value)}
          required
        />
      </div>
      <div className={styles.formInputs}>
        <label htmlFor="city">Ville</label>
        <input
          id="city"
          type="text"
          value={city}
          onChange={e => setCity(e.target.value)}
          required
        />
      </div>
      <div className={styles.formInputs}>
        <label htmlFor="zipCode">Code postal</label>
        <input
          id="zipCode"
          type="text"
          value={zipCode}
          onChange={e => setZipCode(e.target.value)}
          required
        />
      </div>
      <div className={styles.totalCart}>{`Total: ${sum} euros`}</div>
      <div id={styles.cardElement}>
        <CardElement
          options={cardElementOptions}
          onChange={handleCardDetailsChange}
        />
      </div>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <input
        id={styles.acheter}
        disabled={isProcessing || !stripe}
        type="submit"
        value={isProcessing ? "En cours..." : "Acheter"}
      />
    </form>
  )
}

export default CheckoutForm
