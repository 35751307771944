import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import CheckoutForm from "./checkoutForm.js"

const stripe = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

const Checkout = ({ sum }) => {
  return (
    <div>
      <Elements stripe={stripe}>
        <CheckoutForm sum={sum} />
      </Elements>
    </div>
  )
}

export default Checkout
