import React, { useState, useContext } from "react"
import CartContext from "../contexts/CartContext"
import styles from "./panier.module.scss"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Checkout from "../components/checkout"

const Panier = () => {
  const { cart, setCart, removeItem } = useContext(CartContext)
  const [showCheckOut, setShowCheckOut] = useState(false)

  // Change la quantité des articles de moins de 1000 euros dans le select et dans le state du panier
  const changeQuantity = e => {
    const modifiedQuantity = cart.map(item => {
      if (item.id === e.target.id) {
        item.quantity = parseInt(e.target.value, 10)
        return item
      } else {
        return item
      }
    })
    setCart(modifiedQuantity)
  }

  // Calcule le prix total du panier.
  let sum = 0
  ;(function totalPrice() {
    cart.forEach(item => {
      sum += item.quantity * item.price
    })
    return sum
  })()

  return (
    <Layout>
      <main className={styles.container}>
        {cart.length ? <h1 className={styles.monPanier}>Mon Panier</h1> : null}
        {cart.length
          ? cart.map(item => {
              return (
                <section className={styles.item} key={item.id}>
                  <div
                    role="button"
                    tabIndex="0"
                    className={styles.remove}
                    onClick={() => removeItem(item.id)}
                    onKeyDown={e => e.key === "r" ?? removeItem(item.id)}
                  >
                    -
                  </div>
                  <div className={styles.frame}>
                    <img src={require(`../images/${item.directory}`)} alt="." />
                  </div>
                  <ul className={styles.details}>
                    <li>
                      <div className={styles.cross}></div>
                      <h4>{item.title}</h4>
                      <h4>{item.size}</h4>
                      <h4>{item.price} €</h4>
                    </li>
                  </ul>
                  {Number(item.price) > 1000 ? null : (
                    <section className={styles.quantity}>
                      <label htmlFor="item-number">Quantité</label>
                      <select
                        value={item.quantity}
                        onChange={changeQuantity}
                        name="items"
                        id={item.id}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </section>
                  )}
                </section>
              )
            })
          : null}
        {cart.length ? (
          <section className={styles.bottom}>
            <div className={styles.totalCart}>{`Total: ${sum} euros`}</div>
            <button id={styles.acheter} onClick={() => setShowCheckOut(true)}>
              Acheter
            </button>
            {showCheckOut && cart.length > 0 && <Checkout sum={sum} />}
          </section>
        ) : (
          <section className={styles.emptyCart}>
            <p>Votre Panier est vide</p>
            <div className={styles.frame}>
              <img src={require("../images/panier/empty-cart.png")} alt="." />
            </div>
            <ul className={styles.menuBikes}>
              <li>
                <Link activeClassName={styles.activeNavItem} to="/ville">
                  Vélos Ville
                </Link>
              </li>
              <li>
                <Link activeClassName={styles.activeNavItem} to="/speedBike">
                  Speed Bikes
                </Link>
              </li>
              <li>
                <Link activeClassName={styles.activeNavItem} to="/cargo">
                  Vélos Cargo
                </Link>
              </li>
              <li>
                <Link activeClassName={styles.activeNavItem} to="/vtt">
                  VTT
                </Link>
              </li>
              <li>
                <Link activeClassName={styles.activeNavItem} to="/route">
                  Vélos Route
                </Link>
              </li>
              <li>
                <Link activeClassName={styles.activeNavItem} to="/accessoires">
                  Accessoires
                </Link>
              </li>
            </ul>
          </section>
        )}
      </main>
    </Layout>
  )
}

export default Panier
